<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Solving Two Equations with Two Unknowns</h2>
      <p class="mb-3">
        You are always able to determine the value of any number variables as long as you have the
        same number of independent equations. We commonly solve for a value of x in a formula with
        no other unknowns. But it is also common that we will have two separate equations where we
        need to solve for two unknown variables.
      </p>

      <p class="mb-3">
        There are two common approaches to solving such problems. The first is to solve one equation
        for one of the variables with respect to the other. For example, if we had the two following
        equations:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$x+y=9 \\ x-y=3$" />
      </p>

      <p class="mb-3">
        If we only had <stemble-latex content="$x+y=9$" />, there could be numerous solutions. We
        could have x=1 and y=8, x=2 and y=7, x=3 and y=6 and these are only some of the examples
        with positive integers. If we use negative numbers or decimal numbers, there would be an
        infinite number of solutions! But since the values of x and y must ALSO work with the second
        equation, we are far more limited. In fact, there will be only a single answer. So, let's
        solve this first equation for x. This would give us:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$x = 9 - y$" />
      </p>

      <p class="mb-3">
        If we now take this formula for x and substitute it in for x in the OTHER equation, we would
        get the following:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$x - y = 3 \\ (9-y) - y = 3 \\ 9 - 2y = 3$" />
      </p>

      <p class="mb-3">
        Now this second expression now contains a single unknown variable, y. If we solve this
        equation for y, we can then plug that value for y into the original equation in order to
        solve for x! Let's solve for y below:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$9 - 2y = 3 \\ 9 = 3 + 2y \\ 9 - 3 = 2y \\ 6 = 2y \\ y = 3$" />
      </p>

      <p class="mb-3">
        Now we know that y must equal 3. Using this in the original equation gives us:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$x + y = 9 \\ x + 3 = 9 \\ x = 9 - 3 \\ x = 6$" />
      </p>

      <p class="mb-3">
        The second method for solving a set of equations is to add or subtract the two equations.
        For instance, we could take our two equations and add them together. This involves adding
        the left hand sides together and making them equal to the sum of the right hand sides. This
        can be shown like:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\hspace{1.7cm}x + y = 9 \\ +\underline{\hspace{1.4cm}x - y = 3} \\ \hspace{0.3cm} 2x + y - y = 9 + 3$"
        />
      </p>

      <p class="mb-3">
        We can then easily solve this equation using a simple rearrangement as shown below:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$2x + y - y = 9 + 3 \\ 2x = 12 \\ x = 6$" />
      </p>

      <p class="mb-3">
        Since we used both equations to come up with this value for x, we can plug it into either of
        our two equations to determine the value of y:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$x+y=9 \to 6 + y = 9 \to y = 3 \\ \hspace{0.5cm} \text{or} \\ x-y = 3 \to 6 - y = 3 \to y = 3$"
        />
      </p>

      <p class="mb-3">
        Using both equations is a great way to verify your work. If you obtain the same value from
        both equations, chances are, you have the correct answer.
      </p>

      <p class="mb-3">
        Sometimes things won't work out this nicely. Consider the following example:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$x + y = 10 \\ 3x - 2y = 10$" />
      </p>

      <p class="mb-3">
        In this case, if we tried adding or subtracting these equations, we would still end up with
        an expression containing both unknown variables. In such cases, you have to multiply one or
        both equations by some constant. For instance, if we multiply both sides of the first
        equation by 2, we end up with:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\hspace{1.9cm}2x + 2y = 20 \\ +\underline{\hspace{1.6cm}3x - 2y = 10} \\ \hspace{0.3cm} 5x + 2y - 2y = 20 + 10$"
        />
      </p>

      <p class="mb-3">This then simplifies to:</p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$5x = 30 \\ x = 6$" />
      </p>

      <p class="mb-3">Using this value of x in either of the original equations will give us:</p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$x + y = 10 \to 6 + y = 10 \to y = 4 \\ \hspace{0.5 cm} \text{or} \\ 3x - 2y = 10 \to 3(6) - 2y = 10 \to y = 4$"
        />
      </p>

      <p class="mb-3">
        Either method will work for any set of equations, but method 2 tends to work quite well when
        the equations do not need to be manipulated (i.e. you simply have to add or subtract the
        two, no multiplication needed). Using the methods described here, solve the following sets
        of equations for x and y:
      </p>

      <p class="mb-3">
        <stemble-latex content="$\text{a) }2x + 4y =$" />
        <latex-number :number="exprAVal1" />
        <stemble-latex content="$\hspace{0.2 cm} \text{and} \hspace{0.2cm} 2y - x =$" />
        <latex-number :number="exprAVal2" />
      </p>

      <calculation-input
        v-model="inputs.x1"
        class="mb-2"
        dense
        :prepend-text="'$\\text{x:}$'"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.y1"
        class="mb-5"
        dense
        :prepend-text="'$\\text{y:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\text{b) }x - 3y =$" />
        <latex-number :number="exprBVal1" />
        <stemble-latex content="$\hspace{0.2 cm} \text{and} \hspace{0.2cm} 2x + y =$" />
        <latex-number :number="exprBVal2" />
      </p>

      <calculation-input
        v-model="inputs.x2"
        class="mb-2"
        dense
        :prepend-text="'$\\text{x:}$'"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.y2"
        class="mb-5"
        dense
        :prepend-text="'$\\text{y:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{c) }5y - 3x =$" />
        <latex-number :number="exprCVal1" />
        <stemble-latex content="$\hspace{0.2 cm} \text{and} \hspace{0.2cm} 2y - 3x + 8 =$" />
        <latex-number :number="exprCVal2" />
      </p>

      <calculation-input
        v-model="inputs.x3"
        class="mb-2"
        dense
        :prepend-text="'$\\text{x:}$'"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.y3"
        class="mb-5"
        dense
        :prepend-text="'$\\text{y:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question352',
  components: {
    CalculationInput,
    StembleLatex,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        x1: null,
        y1: null,
        x2: null,
        y2: null,
        x3: null,
        y3: null,
      },
    };
  },
  computed: {
    xVal1() {
      return this.taskState.getValueBySymbol('xVal1').content;
    },
    yVal1() {
      return this.taskState.getValueBySymbol('yVal1').content;
    },
    xVal2() {
      return this.taskState.getValueBySymbol('xVal2').content;
    },
    yVal2() {
      return this.taskState.getValueBySymbol('yVal2').content;
    },
    xVal3() {
      return this.taskState.getValueBySymbol('xVal3').content;
    },
    yVal3() {
      return this.taskState.getValueBySymbol('yVal3').content;
    },
    exprAVal1() {
      return (2 * this.xVal1.toFloat() + 4 * this.yVal1.toFloat()).toFixed(0);
    },
    exprAVal2() {
      return (2 * this.yVal1.toFloat() - this.xVal1.toFloat()).toFixed(0);
    },
    exprBVal1() {
      return (this.xVal2.toFloat() - 3 * this.yVal2.toFloat()).toFixed(1);
    },
    exprBVal2() {
      return (2 * this.xVal2.toFloat() + this.yVal2.toFloat()).toFixed(1);
    },
    exprCVal1() {
      return (5 * this.yVal3.toFloat() - 3 * this.xVal3.toFloat()).toFixed(1);
    },
    exprCVal2() {
      return (2 * this.yVal3.toFloat() - 3 * this.xVal3.toFloat() + 8.0).toFixed(1);
    },
  },
};
</script>
